import React from 'react';
import classnames from 'classnames';

import Heading from 'components/shared/heading/Heading';

import ProductDetails from './components/product-details/ProductDetails';

import styles from './MembershipShop.module.scss';

interface IMembershipShopProps {
  className?: string;
  setRef?: React.RefObject<any>;
}

function MembershipShop({ className, setRef }: IMembershipShopProps) {
  return (
    <section ref={setRef} className={classnames(styles.screenBox, className)} id="swag">
      <header className={styles.membershipRedemtionHeader}>
        <Heading
          color="#ff3800"
          shadowColor="#ff0ba9"
          underline={false}
          className={styles.membershipRedemtionTitle}
        >
          You've earned it.
        </Heading>

        <Heading
          color="#ff3800"
          shadowColor="#ff0ba9"
          underlineColor="#ff0ba9"
          underlineDirection="end"
          underlineOffset="20px"
          className={styles.membershipRedemtionTitle}
        >
          Redeem those points
        </Heading>
      </header>
      <ProductDetails />
    </section>
  );
}

export default MembershipShop;
