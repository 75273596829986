import React from 'react';
import classnames from 'classnames';
import Heading from 'components/shared/heading/Heading';

import styles from './MembershipAbout.module.scss';

interface Props {
  className?: string;
  setRef?: React.RefObject<HTMLDivElement>;
}

const MembershipAbout: React.FC<Props> = ({ className, setRef }) => (
  <section ref={setRef} className={classnames(className, styles.membershipAbout)}>
    <header className={styles.membershipAboutHeader}>
      <Heading
        color="#ff3800"
        shadowColor="#ff0ba9"
        underlineColor="#ff0ba9"
        underlineDirection="start"
        underlineOffset="20px"
        className={styles.membershipAboutTitle}
        data-description="There are 4 levels you can reach in CLICK Rewards. As you earn more points, you climb closer to the next level up which offers bigger, more luxurious, show-stopping rewards and prizes, like our top-secret grand prize. "
      >
        all about the tiers
      </Heading>
    </header>
  </section>
);

export default MembershipAbout;
