import React from 'react';

import { ILink } from './links';

interface ILinkProps extends ILink {}

const Link = ({ icon, href, queryParams }: ILinkProps) => (
  <a href={`${href}?${new URLSearchParams(queryParams)}`} rel="noreferrer" target="_blank">
    <img src={icon} />
  </a>
);

export default Link;
