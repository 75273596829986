import React from 'react';
import copy from 'copy-to-clipboard';
import { NotificationManager } from 'react-notifications';

import useAuth from '../../../../../hooks/useAuth';
import { updateProfile } from '../../../../../services';
import { Profile } from '../../../../../services/interfaces';

import iconCopy from '../../../../../images/Copy.svg';
import iconEdit from '../../../../../images/edit.svg';
import iconApply from '../../../../../images/green-checkmark.png';
import iconCancel from '../../../../../images/cross.svg';

import styles from './ReferalCode.module.scss';

function ReferalCode() {
  const authState = useAuth().state;
  const profile = authState.profile as Profile;
  const accessToken = authState.token || '';

  const [inviteCode, setInviteCode] = React.useState(profile?.inviteCode || '');
  const [localInviteCode, setLocalInviteCode] = React.useState(inviteCode);
  const [isDisabled, setDisabled] = React.useState(true);

  const clipBoard = () => {
    copy(`${window.location.origin}/invite?c=${localInviteCode}`);
    NotificationManager.success('Copied');
  };
  const editStart = () => setDisabled(false);
  const editApply = () => {
    updateProfile({ ...profile, inviteCode: localInviteCode }, accessToken)
      .then(() => {
        setInviteCode(localInviteCode);
        NotificationManager.success('Saved');
      })
      .catch(() => {
        setLocalInviteCode(inviteCode);
        NotificationManager.warning('Not saved');
      })
      .finally(() => {
        setDisabled(true);
      });
  };
  const editCancel = () => {
    setLocalInviteCode(inviteCode);
    setDisabled(true);
  };

  return (
    <div className={styles.field}>
      <span className={styles.fieldTitle}>REFERRAL CODE</span>
      <label className={styles.fieldLabel}>
        <input
          className={styles.fieldInput}
          type="text"
          value={localInviteCode}
          onChange={(event) => setLocalInviteCode(event.target.value)}
          disabled={isDisabled}
        />

        {isDisabled && (
          <button
            type="button"
            className={styles.fieldButton}
            onClick={clipBoard}
            title="Copy to clipboard"
          >
            <img src={iconCopy} alt="copy to clipboard" />
          </button>
        )}

        {isDisabled && (
          <button type="button" className={styles.fieldButton} onClick={editStart} title="Edit">
            <img src={iconEdit} alt="edit" />
          </button>
        )}

        {!isDisabled && (
          <button type="button" className={styles.fieldButton} onClick={editApply} title="Apply">
            <img src={iconApply} alt="apply" />
          </button>
        )}

        {!isDisabled && (
          <button type="button" className={styles.fieldButton} onClick={editCancel} title="Cancel">
            <img src={iconCancel} alt="cancel" />
          </button>
        )}
      </label>
      <span className={styles.fieldError}></span>
    </div>
  );
}

export default ReferalCode;
