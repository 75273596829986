import React from 'react';
import bgTriangleChill from 'images/Chill.svg';
import Layout from 'components/layouts/main/Layout';
import styles from './ProductInfo.module.scss';
import Dropbox from 'components/shared/dropbox/DropboxEmbed';

class ProductInfo extends React.Component<any> {
  constructor(props) {
    super(props);
  }

  render() {
    const { location } = this.props;

    return (
      <Layout location={location.pathname}>
        <section className={styles.screenBox}>
          <div className={styles.tabsBox}>
            <div className={styles.tab}>
              <Dropbox className={styles.dropboxEmbed} />
            </div>
          </div>
          <div
            className={styles.triangleBackground}
            style={{ backgroundImage: `url(${bgTriangleChill})` }}
          ></div>
        </section>
      </Layout>
    );
  }
}

export default ProductInfo;
