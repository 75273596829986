import React, { useRef } from 'react';
import { Field, FieldProps } from 'formik';
import copy from 'copy-to-clipboard';
import { NotificationManager } from 'react-notifications';

import checkmark from '../../../../images/green-checkmark.png';
import editIcon from '../../../../images/edit.svg';
import copyIcon from '../../../../images/Copy.svg';

import styles from './Form.module.scss';

interface Props {
  value: string;
  placeholder?: string;
  type: string;
  name: string;
  mask?: string;
  maskChar?: string;
  label?: string;
  isEditIcon?: boolean;
  Required?: boolean;
  isCopyIcon?: boolean;
  validate?: any;
  onChange: (value: string) => void | undefined;
  onBlur?: ((event: React.FocusEvent<HTMLInputElement>) => void) | undefined;
}

const Textarea: React.FC<Props> = (props: Props) => {
  const {
    mask,
    validate,
    name,
    placeholder,
    label,
    Required,
    isEditIcon,
    isCopyIcon,
    type,
    onChange,
    value,
  } = props;
  const inputRef = useRef<HTMLInputElement>(null);
  const padding = 10 + (isCopyIcon ? 24 : 0) + (validate ? 24 : 0);

  const onCopy = (value: string) => {
    copy(value);
    NotificationManager.success('Copied');
  };
  return (
    <Field name={name} validate={validate}>
      {({ field, meta }: FieldProps) => (
        <label className={styles.formField}>
          {label && (
            <span className={styles.formLabel}>
              {label}
              {Required && '*'}
            </span>
          )}
          <div className={styles.inputContainer}>
            <div
              className={styles.textarea}
              contentEditable={true}
              ref={inputRef}
              placeholder={placeholder}
              style={{ paddingRight: `${padding}px` }}
              dangerouslySetInnerHTML={{ __html: value }}
              onBlur={(event) => {
                onChange(event.target.innerText);
              }}
            />

            <div className={styles.listIcons}>
              {isCopyIcon && field.value && (
                <img
                  className={styles.clickable}
                  onClick={() => onCopy(field.value)}
                  src={copyIcon}
                />
              )}
              {!meta.error && value ? (
                <img src={checkmark} />
              ) : (
                isEditIcon && <img src={editIcon} />
              )}
            </div>
            {meta.error && <span className={styles.error}>{meta.error}</span>}
          </div>
        </label>
      )}
    </Field>
  );
};

export default Textarea;
