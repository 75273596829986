import React from 'react';
import classnames from 'classnames';
import { FluidObject } from 'gatsby-image';
import Img from 'gatsby-image/withIEPolyfill';

import useRewards from 'hooks/useRewards';

import Heading from 'components/shared/heading/Heading';
import ProgressBar from './progress-bar/ProgressBar';
import ProfileWidget from './profile-widget/ProfileWidget';

import styles from './MembershipHeader.module.scss';

export interface MembershipRegisterProps {
  className?: string;
  setRef?: React.RefObject<any>;
  goToProfile(): any;
  bgImage: FluidObject;
  refLinks: refsObject;
}

export interface refsObject {
  register: React.RefObject<any>;
  earnPoints: React.RefObject<any>;
  swag: React.RefObject<any>;
  about: React.RefObject<any>;
}

function MembershipHeader({ className, refLinks, bgImage, goToProfile }: MembershipRegisterProps) {
  const { awardedPoints } = useRewards();
  const goToSection = function (ref: React.RefObject<any>) {
    ref.current?.scrollIntoView({
      behavior: 'smooth',
    });
  };

  return (
    <section className={styles.screenBox}>
      <Img
        fadeIn={false}
        className={styles.pictureBackground}
        fluid={bgImage}
        objectPosition="0% 50%"
      />

      <div className={classnames(styles.textWrapper, className)}>
        <Heading color="#ffffff" shadowColor="#FF0BA9" underline={false} className={styles.title}>
          Welcome to CLICK rewards
        </Heading>

        <div className={styles.navigationLinks}>
          <div onClick={() => goToSection(refLinks.earnPoints)}>Earn points</div>
          <div onClick={() => goToSection(refLinks.swag)}>Redeem points</div>
          {/* <div onClick={() => goToSection(refLinks.about)}>About Rewards</div> */}
        </div>

        <div className={styles.navigationButton}>
          <div onClick={() => goToSection(refLinks.register)}>register your purchase</div>
        </div>

        <ProfileWidget className={styles.profileWidget} goToEditProfile={goToProfile} />

        <ProgressBar className={styles.progressBar} points={awardedPoints} />

        <p className={styles.description}>
          Each time you earn points, you get closer and closer to the next level. There, you’ll find
          grand prizes and gain access to new CLICK rewards galore. Keep earning points, keep
          redeeming for rewards, keep CLICKing.
        </p>
      </div>
    </section>
  );
}

export default MembershipHeader;
