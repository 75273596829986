import * as loadScript from 'load-script';
import React from 'react';

const DROPBOX_SDK_URL = 'https://www.dropbox.com/static/api/2/dropins.js';
const DROPBOX_SHARED_URL =
  'https://www.dropbox.com/sh/kceus6v4h6zymq7/AAAFqfP58sHlQDXTBpG-s_XFa?dl=0';
const SCRIPT_ID = 'dropboxjs';

let scriptLoadingStarted = false;

class DropboxEmbed extends React.Component<any> {
  dbxRef: any;

  constructor(props) {
    super(props);
    this.dbxRef = React.createRef();
  }

  componentDidMount() {
    if (!this.isDropboxReady() && !scriptLoadingStarted) {
      scriptLoadingStarted = true;
      loadScript(DROPBOX_SDK_URL, {
        attrs: {
          id: SCRIPT_ID,
          'data-app-key': 'bve3gqaj2abvnit',
        },
      });
    }
    const interval = setInterval(() => {
      if (this.isDropboxReady() && this.dbxRef.current) {
        clearInterval(interval);
        const options = {
          link: DROPBOX_SHARED_URL,
          file: {
            zoom: 'best',
          },
          folder: {
            view: 'grid',
            headerSize: 'small',
          },
        };
        (window as any).Dropbox.embed(options, this.dbxRef.current);
      }
    }, 200);
  }

  isDropboxReady() {
    return !!(window as any).Dropbox;
  }

  render() {
    return <div style={Object.assign({}, { height: '100%' })} ref={this.dbxRef}></div>;
  }
}

export default DropboxEmbed;
