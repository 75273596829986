import React, { useRef } from 'react';
import { Field, FieldProps } from 'formik';
import copy from 'copy-to-clipboard';
import { NotificationManager } from 'react-notifications';
import checkmark from 'images/green-checkmark.png';
import editIcon from 'images/edit.svg';
import copyIcon from 'images/Copy.svg';
import styles from './Form.module.scss';
import InputMask from 'react-input-mask';

interface Props {
  value?: string;
  placeholder?: string;
  type: string;
  name: string;
  mask?: string;
  maskChar?: string;
  label?: string;
  isEditIcon?: boolean;
  Required?: boolean;
  isCopyIcon?: boolean;
  validate?: any;
  maxLength?: number;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void | undefined;
  onBlur?: ((event: React.FocusEvent<HTMLInputElement>) => void) | undefined;
}

const FormField: React.FC<Props> = (props: Props) => {
  const {
    mask,
    validate,
    name,
    placeholder,
    label,
    Required,
    maskChar,
    isEditIcon,
    isCopyIcon,
    type,
    maxLength,
  } = props;
  const inputRef = useRef<HTMLInputElement>(null);
  const padding = 10 + (isCopyIcon ? 24 : 0) + (validate ? 24 : 0);

  const onCopy = (value: string) => {
    copy(value);
    NotificationManager.success('Copied');
  };
  return (
    <Field name={name} validate={validate}>
      {({ field, meta }: FieldProps) => (
        <label className={styles.formField}>
          {label && (
            <span className={styles.formLabel}>
              {label}
              {Required && '*'}
            </span>
          )}
          <div className={styles.inputContainer}>
            {mask ? (
              <InputMask
                {...field}
                placeholder={placeholder}
                maskChar={maskChar || ''}
                mask={mask || ''}
              />
            ) : (
              <input
                ref={inputRef}
                {...field}
                type={type}
                placeholder={placeholder}
                style={{ paddingRight: `${padding}px` }}
                onChange={(e) => {
                  let new_value = `${e.target.value}`;
                  e.target.value = maxLength
                    ? new_value.length <= maxLength
                      ? new_value
                      : field.value
                    : e.target.value;
                  field.onChange(e);
                }}
              />
            )}
            <div className={styles.listIcons}>
              {isCopyIcon && field.value && (
                <img
                  className={styles.clickable}
                  onClick={() => onCopy(field.value)}
                  src={copyIcon}
                />
              )}
              {!meta.error && meta.touched && inputRef?.current?.value ? (
                <img src={checkmark} />
              ) : (
                isEditIcon && <img src={editIcon} />
              )}
            </div>
            {meta.error && meta.touched && <span className={styles.error}>{meta.error}</span>}
          </div>
        </label>
      )}
    </Field>
  );
};

export default FormField;
