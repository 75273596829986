import React from 'react';
import classnames from 'classnames';

import Arrow from './Arrow';
import BenefitTriangle from './BenefitTriangle';

import styles from './MembershipBenefits.module.scss';

const BenefitLevelTabBody = ({
  isActive,
  ovalColor,
  mainTriangle,
  boxShadow,
  title,
  description,
  color,
  triangles,
  nextArrowColor,
  previousArrowColor,
  nextTab,
  previousTab,
}: IBenefitLevelTabBodyProps) => (
  <div
    className={classnames(styles.benefit, { [styles.benefitActive]: isActive })}
    style={{ borderColor: ovalColor }}
  >
    <div
      className={styles.benefitTriangleMain}
      style={{
        backgroundImage: `url(${mainTriangle})`,
        // filter: `drop-shadow(${boxShadow})`,
      }}
    >
      <p>{title}</p>
      <div className={styles.benefitTriangleMainDescription} style={{ color }}>
        {description}
      </div>
    </div>

    {(triangles as IBenefitLevelTriangle[]).map((triangle, index) => (
      <BenefitTriangle key={`triangle_${index}`} color={color} {...triangle} />
    ))}

    {nextArrowColor && <Arrow direction="next" color={nextArrowColor} onClick={nextTab} />}
    {previousArrowColor && (
      <Arrow direction="previous" color={previousArrowColor} onClick={previousTab} />
    )}
  </div>
);

export default BenefitLevelTabBody;
