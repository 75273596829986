import React from 'react';
import { FluidObject } from 'gatsby-image';
import { navigate } from 'gatsby';

import useAuth from 'hooks/useAuth';

import ValidatedAccount from '../ValidatedAccount';
import Layout from 'components/layouts/main/Layout';
import Header from 'components/shopHeader/Header';

import styles from './Checkout.module.scss';

interface Props {
  location: Location;
  bgImage: FluidObject;
  friendsShareBackground: FluidObject;
}
const SuccessCheckout: React.FC<Props> = ({}) => {
  const { profile } = useAuth().state;
  return (
    <Layout isSideMenu={false} location={location.pathname} className={styles.layout}>
      <Header
        backImageColor={'#FF3800'}
        className={styles.logoHeader}
        printLocation={false}
        goBack={() => navigate('/account#swag')}
      />
      <div className={styles.background} />
      <section className={styles.checkout}>
        <header className={styles.title}>All done!</header>
        <div className={styles.body}>
          <p>Your Rewards purchase has been received and will be delivered to you soon.</p>
          <p>Keep an eye out for a confirmation email.</p>
          <p>Feel free to contact us at rewards@clickspray.com for any questions on your order.</p>
          <p>
            Refer your friends to{' '}
            <a href="https://clickspray.com/" target="_blank">
              www.clickspray.com
            </a>{' '}
            and earn rewards faster!
          </p>
          <p>Enjoy!</p>
        </div>
      </section>
    </Layout>
  );
};

const AccountValidate = ({ location, bgImage, friendsShareBackground }: Props) => (
  <ValidatedAccount
    exact
    path="/account/checkout/success"
    component={SuccessCheckout}
    location={location}
    bgImage={bgImage}
    friendsShareBackground={friendsShareBackground}
  />
);

export default AccountValidate;
