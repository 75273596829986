/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

export default {
  container: (provided: any) => ({
    ...provided,
    marginTop: '0vw',
    border: 'none',
    padding: '0',
  }),
  valueContainer: (provided: any) => ({
    ...provided,
    padding: '0',
    '@media (max-width: 700px)': {
      overflow: 'unset',
    },
  }),
  control: (provided: any, state: any) => ({
    ...provided,
    border: 'none',
    boxShadow: 'none',
    borderRadius: 0,
    outline: 0,
    minHeight: '30',
    backgroundColor: 'transparent',
    textTransform: 'uppercase',
    borderColor: 'transparent',
    borderBottom: '1px #fff solid',
    padding: '0',
    mixBlendMode: 'normal',
    ':hover': {
      border: 'none',
      borderBottom: '1px #fff solid',
      boxShadow: 'none',
    },
    '@media (max-width: 700px)': {
      minHeight: '50px',
    },
  }),
  option: (provided: any) => ({
    ...provided,
    backgroundColor: 'white',
    color: '#626262',
    cursor: 'pointer',
    fontWeight: 700,
    fontSize: '14px',
    fontFamily: 'Gotham',
    ':hover': {
      backgroundColor: '#f2f2f2',
    },
  }),
  singleValue: (provided: any) => ({
    ...provided,
    fontFamily: 'Gotham',
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '100%',
    color: '#626262',
    overflow: 'unset',
    whiteSpace: 'normal',
  }),
  input: (provided: any) => ({
    ...provided,
    outline: 0,
    fontFamily: 'Gotham',
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '100%',
    color: '#626262',
    width: '100%',
  }),
  placeholder: (provided: any) => ({
    ...provided,
    outline: 0,
    fontFamily: 'Gotham',
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '100%',
    color: '#626262',
    opacity: '0.35',
    '@media (max-width: 850px)': {
      marginRight: '20px',
    },
    textAlign: 'left',
  }),
  menu: (provided: any) => ({
    ...provided,
    marginTop: '0px',
    borderRadius: 0,
    '@media (max-width: 850px)': {
      marginTop: '5px',
    },
  }),
  indicatorSeparator: (provided: any) => ({
    ...provided,
    display: 'none',
  }),
};
