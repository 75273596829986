import React from 'react';
import classnames from 'classnames';

import Heading from 'components/shared/heading/Heading';
import MembershipEditProfileForm from './MembershipProfileEditForm';

import styles from './MembershipProfileEdit.module.scss';

const MembershipEditProfile: React.FC<IMembershipEditProfileProps> = ({ className, setRef }) => (
  <section ref={setRef} className={classnames(styles.screenBox, className)}>
    <header>
      <Heading
        color="#ff3800"
        shadowColor="#ff0ba9"
        underlineColor="#ff0ba9"
        underlineDirection="start"
        underlineOffset="20px"
        className={styles.membershipProfileEditTitle}
      >
        edit profile
      </Heading>
    </header>
    <div className={styles.membershipProfileEditBody}>
      <MembershipEditProfileForm />
    </div>
  </section>
);

export default MembershipEditProfile;
