import React from 'react';

interface IProgressBarSliderProps {
  points: number;
  level: number;
  direction: 'left' | 'top';
  position: number;
  className?: string;
}

function ProgressBarSlider(props: IProgressBarSliderProps) {
  let { points, level, direction, position, className } = props;

  return (
    <div
      className={className}
      data-points={`${points} LIFETIME POINTS`}
      data-level={`level ${level}`}
      style={{ [direction]: `calc(${position}% - 14px)` }}
    >
      <svg width="9" height="31" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M4.85.15a.5.5 0 00-.7 0L.96 3.33a.5.5 0 10.71.7L4.5 1.22l2.83 2.83a.5.5 0 10.7-.71L4.86.15zm-.7 30.7c.2.2.5.2.7 0l3.19-3.18a.5.5 0 10-.71-.7L4.5 29.78l-2.83-2.83a.5.5 0 10-.7.71l3.18 3.18zM4 .5v30h1V.5H4z"
          fill="#5E91FF"
        />
      </svg>
    </div>
  );
}

export default ProgressBarSlider;
