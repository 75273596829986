import React from 'react';

import BackgroundImageFile from 'images/modal-success-bg.png';
import MaskImageFile from 'images/modal-mask.png';
import CrossImageFile from 'images/cross.svg';

import styles from './Modal.module.scss';

interface Props {
  show?: boolean;
  onHide: (() => void) | undefined;
}

const Modal: React.ReactNode = ({ show, onHide }: Props) =>
  Boolean(show) && (
    <div className={styles.mask}>
      <div className={styles.modal}>
        <div className={styles.close}>
          <img onClick={onHide} src={CrossImageFile} alt="close" />
        </div>
        <div className={styles.body}>
          <div
            className={styles.headerBackground}
            style={{
              backgroundImage: `url(${BackgroundImageFile})`,
            }}
          >
            <div
              className={styles.headerMask}
              style={{
                backgroundImage: `url(${MaskImageFile})`,
              }}
            />
            <div className={styles.modalTitle}>Thank you!</div>
          </div>
          <div className={styles.content}>
            <p>You've successfully earned</p>
            <p className={styles.importantText}>30 points.</p>
            <p>Keep up the good work and keep on CLICKing.</p>
          </div>
        </div>
      </div>
    </div>
  );

export default Modal;
