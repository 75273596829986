import React from 'react';
import { Field, FieldProps } from 'formik';
import styles from './Form.module.scss';

interface Props {
  values: valuesObject[];
  label?: string;
  name: string;
  validate?: any;
}
interface valuesObject {
  label: string;
  value: string;
}
const Radio: React.FC<Props> = (props: Props) => {
  const { name, label, validate, values } = props;

  return (
    <Field name={name} validate={validate}>
      {({ field, meta }: FieldProps) => (
        <div className={styles.formField}>
          <div className={styles.radioBox}>
            <span className={styles.formLabel}>{label}</span>
            <div className={styles.radioOptionsContainer}>
              {values.map((item) => (
                <label className={styles.radioContainer} key={item.value}>
                  <input
                    {...field}
                    type="radio"
                    id={item.value}
                    value={item.value}
                    checked={field.value === item.value}
                  />
                  <span className={styles.radioBtn}></span>
                  {item.label}
                </label>
              ))}
            </div>
          </div>
          {meta.error && meta.touched && <span className={styles.error}>{meta.error}</span>}
        </div>
      )}
    </Field>
  );
};

export default Radio;
