import React from 'react';

import icon1 from 'images/membership-benefits-1.svg';
import icon2 from 'images/membership-benefits-2.svg';

import mainTriangle1 from 'images/1-membership-benefit.png';
import mainTriangle2 from 'images/2-membership-benefit.png';
import mainTriangle3 from 'images/3-membership-benefit.png';
import mainTriangle4 from 'images/4-membership-benefit.png';

export const benefitLevels = [
  {
    title: (
      <>
        <span>
          SIGN
          <br />
          UP
        </span>
      </>
    ),
    description: 'LEVEL 1',
    boxShadow: '4px 2px 60px rgba(255, 11, 169, 0.8)',
    color: '#FF3800',
    ovalColor: '#FF0BA9',
    mainTriangle: mainTriangle1,
    triangles: [
      {
        title: (
          <span>
            1X
            <br />
            POINTS
          </span>
        ),
        description: 'ALL PURCHASES',
        position: 'top',
      },
      {
        title: <img src={icon1} />,
        description: 'BRANDANA',
        position: 'bottom',
      },
    ],
    previousArrowColor: false,
    nextArrowColor: '#00E087',
  },
  {
    title: (
      <>
        Points
        <br />
        <span>300</span>
      </>
    ),
    description: 'LEVEL 2',
    boxShadow: '4px 2px 60px rgba(195, 255, 64, 1)',
    color: '#00E087',
    ovalColor: '#C3FF40',
    mainTriangle: mainTriangle2,
    triangles: [
      {
        title: (
          <span>
            2X
            <br />
            POINTS
          </span>
        ),
        description: 'ALL PURCHASES',
        position: 'top',
      },
      {
        title: <img src={icon1} />,
        description: 'T-SHIRT / HAT',
        position: 'left',
      },
      {
        title: <img src={icon2} />,
        description: (
          <>
            BI-MONTHLY
            <br />
            EVENT
          </>
        ),
        position: 'right',
      },
    ],
    previousArrowColor: '#FF3800',
    nextArrowColor: '#5E91FF',
  },
  {
    title: (
      <>
        Points
        <br />
        <span>2000</span>
      </>
    ),
    description: 'LEVEL 3',
    boxShadow: '4px 2px 60px rgba(0, 255, 255, 1)',
    color: '#5E91FF',
    ovalColor: '#00FFFF',
    mainTriangle: mainTriangle3,
    triangles: [
      {
        title: (
          <span>
            3X
            <br />
            POINTS
          </span>
        ),
        description: 'ALL PURCHASES',
        position: 'top',
      },
      {
        title: <img src={icon1} />,
        description: (
          <>
            BAG WITH
            <br />
            BEACH TOWEL, WORKOUT BAND,
            <br />& KEYCHAIN
          </>
        ),
        position: 'left',
      },
      {
        title: <img src={icon2} />,
        description: (
          <>
            QUARTERLY
            <br />
            DRAWING
          </>
        ),
        position: 'right',
      },
    ],
    previousArrowColor: '#00E087',
    nextArrowColor: '#3308C2',
  },
  {
    title: (
      <>
        Points
        <br />
        <span>7500</span>
      </>
    ),
    description: 'LEVEL 4',
    boxShadow: '4px 2px 60px rgba(183, 64, 255, 0.9)',
    color: '#3308C2',
    ovalColor: '#B740FF',
    mainTriangle: mainTriangle4,
    triangles: [
      {
        title: (
          <span>
            4X
            <br />
            POINTS
          </span>
        ),
        description: 'ALL PURCHASES',
        position: 'top',
      },
      {
        title: <img src={icon1} />,
        description: (
          <>
            JACKET, SHOES
            <br />
            OR BAG
          </>
        ),
        position: 'left',
      },
      {
        title: <img src={icon2} />,
        description: (
          <>
            QUARTERLY
            <br />
            DRAWING
          </>
        ),
        position: 'right',
      },
    ],
    previousArrowColor: '#5E91FF',
    nextArrowColor: false,
  },
];
