import React from 'react';
import classnames from 'classnames';
import { NotificationManager } from 'react-notifications';

import useAuth from 'hooks/useAuth';

import styles from './Form.module.scss';

const Password: React.FC = () => {
  const { profile, authService } = useAuth().state;
  const [inProgress, setProgress] = React.useState(false);

  const forgotPassword = () => {
    setProgress(true);

    fetch(`${authService?._config.baseUrl}/api/v1/authn/recovery/password`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        username: profile?.email,
        factorType: 'EMAIL',
      }),
    })
      .then((response) => response.json())
      .then((result) => {
        if (result.errorSummary) throw result;

        NotificationManager.success(
          `Email has been sent to ${profile?.email} with instructions on resetting your password.`,
        );
      })
      .catch((error) => NotificationManager.warning(error.errorSummary))
      .finally(() => setProgress(false));
  };

  return (
    <div className={classnames(styles.formField, styles.flex)}>
      <span className={styles.formLabel}>password</span>

      <button className={styles.reset} type="button" onClick={forgotPassword} disabled={inProgress}>
        reset password
      </button>
    </div>
  );
};

export default Password;
