import React from 'react';
import classnames from 'classnames';
import CodeRegisterForm from './CodeRegisterForm';
import video from '../../../../videos/CodeRegister.mp4';
import Heading from '../../../shared/heading/Heading';

import styles from './CodeRegisterForm.module.scss';

export interface MembershipRegisterProps {
  className?: string;
  setRef?: React.RefObject<any>;
}

export interface ConsumerFormValue {
  code?: string;
  dispensary?: string;
  budtenderName?: string;
}

function CodeRegister({ className, setRef }: MembershipRegisterProps) {
  return (
    <section ref={setRef} className={styles.screenBox}>
      <header className={styles.membershipCodeHeader}>
        <Heading
          color="#ff3800"
          shadowColor="#ff0ba9"
          underline={false}
          className={styles.membershipCodeTitle}
        >
          WHO'S READY TO
        </Heading>

        <Heading
          color="#ff3800"
          shadowColor="#ff0ba9"
          underlineColor="#ff0ba9"
          underlineDirection="end"
          underlineOffset="20px"
          className={styles.membershipCodeTitle}
        >
          earn some points?!
        </Heading>
      </header>

      <div className={classnames(styles.textWrapper)}>
        <div className={styles.codeBlock}>
          <div className={styles.codeVideo}>
            <video playsInline autoPlay muted loop className={styles.videoBackgound}>
              <source src={video} type="video/mp4"></source>
            </video>
          </div>
          <CodeRegisterForm />
        </div>
      </div>
    </section>
  );
}

export default CodeRegister;
