import React from 'react';
import moment from 'moment';

import styles from './MembershipRedemtion.module.scss';

const formatDate = 'DD-MMM-YYYYYY HH:mm:SS';
export interface ConsumerFormValue {
  code?: string;
  dispensary?: string;
  budtenderName?: string;
}

function RedemtionTable({ redemtion }: IRedemtionTableProps) {
  return redemtion.map((item, index) => (
    <div key={index} className={styles.redemtionRow}>
      <div>{item.activity_name || item.redemption_name || item.reason}</div>
      <div>{item.points}</div>
      <div>{item.points_status}</div>
      <div>{moment(item.created_time, formatDate).format('MMMM D, YYYY')}</div>
    </div>
  ));
}

export default RedemtionTable;
