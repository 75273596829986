import iconEmail from 'images/email-green.svg';
import iconFacebook from 'images/facebook-green.svg';
import iconTwitter from 'images/twitter-green.svg';

export interface ILink {
  icon: string;
  href: string;
  queryParams: {
    [key: string]: string;
  };
}

export const message = `Hey friends!\nI'm loving CLICK and their awesome new rewards program. I think you will too (and I mean that).\n\nSign up now and we'll both earn points to redeem for amazing rewards.`;

export default [
  () => ({
    href: 'mailto:',
    queryParams: {
      body: message,
    },
    icon: iconEmail,
  }),
  (u: string) => ({
    href: 'https://www.facebook.com/sharer/sharer.php',
    queryParams: {
      u,
    },
    icon: iconFacebook,
  }),
  (url: string) => ({
    href: 'https://twitter.com/intent/tweet',
    queryParams: {
      text: message,
      url,
    },
    icon: iconTwitter,
  }),
];
