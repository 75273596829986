import React from 'react';
import classnames from 'classnames';

import styles from './MembershipBenefits.module.scss';

const Arrow = ({ direction, color, onClick }: IArrowProps) => (
  <div
    onClick={onClick}
    className={classnames(styles.benefitArrow, {
      [styles.next]: direction === 'next',
      [styles.previous]: direction === 'previous',
    })}
  >
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40">
      <path
        data-name="Fill 1"
        d="M19.02.63a2.16 2.16 0 013.03 0l17.32 17.32a2.15 2.15 0 010 3.03L22.05 38.3a2.16 2.16 0 01-3.03 0l-1.97-1.97a1.81 1.81 0 01-.67-1.51 2.23 2.23 0 01.67-1.52l10.72-10.26H2.14a2.06 2.06 0 01-1.51-.63A2.07 2.07 0 010 20.9v-2.85a2.06 2.06 0 01.63-1.52 2.07 2.07 0 011.51-.63h25.63L17.05 5.62a2.23 2.23 0 01-.67-1.51 1.81 1.81 0 01.67-1.52z"
        fill={color}
      />
    </svg>
  </div>
);

export default Arrow;
