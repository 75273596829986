import React, { useEffect, useState } from 'react';
import classnames from 'classnames';

import useAuth from 'hooks/useAuth';
import { getRewardsTransactions } from 'services';

import Heading from '../../../shared/heading/Heading';
import RedemtionTable from './RedemtionTable';

import styles from './MembershipRedemtion.module.scss';

export interface MembershipRedemtionProps {
  className?: string;
  setRef?: React.RefObject<any>;
}

export interface ConsumerFormValue {
  code?: string;
  dispensary?: string;
  budtenderName?: string;
}

const SHOW_COUNT = 5;

function MembershipRedemtion({ className, setRef }: MembershipRedemtionProps) {
  const [rewards, setRewards] = useState([]);
  const [printRewards, setPrintRewards] = useState([]);
  const [showAll, setShowAll] = useState(false);
  const {
    state: { token, isAuthenticated },
  } = useAuth();

  useEffect(() => {
    if (isAuthenticated) {
      getRewardsTransactions(token as string)
        .then((res) => res.json())
        .then(setRewards);
    }
  }, [token, isAuthenticated]);

  useEffect(() => {
    if (rewards && rewards.length) {
      setPrintRewards(rewards.slice(0, showAll ? rewards.length : SHOW_COUNT));
    }
  }, [showAll, rewards]);

  return (
    <section ref={setRef} className={classnames(styles.membershipRedemtion, className)}>
      <div className={styles.membershipRedemtionBackground} />
      <header className={styles.membershipRedemtionHeader}>
        <Heading
          color="#ff3800"
          shadowColor="#ff0ba9"
          underlineColor="#ff0ba9"
          underlineDirection="end"
          underlineOffset="20px"
          className={styles.membershipRedemtionTitle}
        >
          Redemption History
        </Heading>
      </header>
      <div className={styles.membershipRedemtionBody}>
        {!rewards.length && <div className={styles.redemtionNodata}>No data</div>}
        <div className={classnames(styles.redemtionRow, styles.redemtionHeaderRow)}>
          <div>activity</div>
          <div>points</div>
          <div>status</div>
          <div>date</div>
        </div>
        <RedemtionTable redemtion={printRewards} />
        {rewards.length > SHOW_COUNT && (
          <button className={styles.redemtionButton} onClick={() => setShowAll(!showAll)}>
            {showAll ? 'VIEW LESS' : 'VIEW MORE'}
          </button>
        )}
      </div>
    </section>
  );
}

export default MembershipRedemtion;
