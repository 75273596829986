import { Form, Formik, Field, FieldProps } from 'formik';
import React, { useState, useRef, useEffect } from 'react';
import { isBrowser } from 'browser-or-node';
import classnames from 'classnames';
import InputMask from 'react-input-mask';
import { NotificationManager } from 'react-notifications';
import { navigate } from 'gatsby';
import AsyncSelect from 'react-select/async';
import _ from 'lodash';
import {
  OrderService,
  AddressService,
  MeService,
  PaymentMethod,
  DeliveryType,
  MenuService,
  PlaceService,
} from '@legalize/sdk';

import useRewards from 'hooks/useRewards';
import { generateUUID } from 'services/helpers';
import StorageService from 'core/services/storageService/StorageService';
import * as clicApi from 'services';
import * as ValidationAlgorithms from 'services/helpers/ValidationAlgorithms';
import useAuth from 'hooks/useAuth';
import SwagAnalytics from 'core/framework/analytic/Swag';
import MarketService from 'core/services/marketService/MarketService';
import { marketId, brandIds, categoryMerchIds, legalizeApiUrl } from 'services/config';

import Cart from '../cart/Cart';
import Preloader from 'components/shared/preloader/Preloader';

import styles from './Form.module.scss';
import selectCustomStyle from './SelectCustomStyle';

interface ISwag {
  skuId: number;
  name: string;
  imageUrl: string;
  quantity: number;
  productId: number;
  price: number;
  attributes: IAttribute[];
}
interface IAttribute {
  id: string;
  name: string;
  value: string;
  isVisible?: boolean;
  possibleValues: string;
}
interface ISize {
  skuId: string;
  quantity: number;
  value: string;
  label: string;
}

const CheckoutForm = () => {
  const { awardedPoints } = useRewards();

  const productStorage = new StorageService(
    (isBrowser && window.sessionStorage) || undefined,
    'productId',
  );

  const placeService = new PlaceService(legalizeApiUrl);
  const sessionId = generateUUID();

  const productId = productStorage.read();
  if (!productId) {
    navigate('/account');
  }

  const {
    state: { token, profile },
  } = useAuth();

  const phone = profile?.phone;

  const [skus, setSkus] = useState<ISwag[]>([]);

  const [loading, setLoading] = useState(false);
  const [selectedSize, setSelectedSize] = useState({} as ISize);
  const [sizes, setSizes] = useState([] as ISize[]);

  const [isEditAddress, setIsEditAddress] = useState(false);

  const [accessToken, setAccessToken] = useState('');

  const [apt, setApt] = useState('');

  const addressRef: React.RefObject<any> = useRef(null);
  const phoneRef: React.RefObject<any> = useRef(null);

  const [quantity, setQuantity] = useState(1);

  const loadOptions = (inputValue: string, callback: (p: any) => void) => {
    inputValue.length
      ? getPlaceAutocomplete(inputValue, (res) => {
          callback(res);
        })
      : callback([]);
  };

  const debouncedLoadOptions: any = _.debounce(loadOptions, 500);

  const addressCreate = (placeId: string, note: string) => {
    const addressService = new AddressService(legalizeApiUrl, accessToken);

    return new Promise((resolve, reject) =>
      addressService
        .create(placeId, note, '')
        .then((resultData: any) => {
          resolve(resultData?.entity?.id || null);
        })
        .catch(reject),
    );
  };

  const getCart = () => {
    const meService = new MeService(legalizeApiUrl, accessToken);

    return new Promise((resolve, reject) =>
      meService
        .getCartId()
        .then((resultData: any) => {
          resolve(resultData);
        })
        .catch(reject),
    );
  };

  const orderCreate = (addressId: number, data: Cart.Order[]) => {
    const orderService = new OrderService(legalizeApiUrl, accessToken);
    return new Promise((resolve, reject) =>
      orderService
        .create(marketId, addressId, data)
        .then((resultData: any) => {
          resolve(resultData?.entity?.id || null);
        })
        .catch(reject),
    );
  };

  const orderCheckout = (orderId: number) => {
    const orderService = new OrderService(legalizeApiUrl, accessToken);
    return new Promise((resolve, reject) =>
      orderService
        .checkout(orderId, PaymentMethod.CASH, DeliveryType.ON_DEMAND)
        .then((resultData: any) => {
          resolve(resultData);
        })
        .catch(reject),
    );
  };

  const onSubmit = (formData: any) => {
    if (awardedPoints < skus[0].price * quantity) {
      NotificationManager.warning('Not enough points');
      return;
    }

    let address_note = `phone: ${formData.phone}`;
    if (apt) {
      address_note = `${address_note}. APT# ${apt}`;
    }

    const data: Cart.Order[] = [
      {
        skuId: Number(selectedSize.skuId),
        quantity: quantity,
      },
    ];
    setLoading(true);

    getCart()
      .then(() => addressCreate(formData.address.placeId, address_note))
      .then((response) => orderCreate(Number(response), data))
      .then((response) => orderCheckout(Number(response)))
      .then((response) => {
        if (response) {
          SwagAnalytics.placeOrder();
          navigate('/account/success');
        } else {
          NotificationManager.warning('warning');
        }
      })
      .catch(() => NotificationManager.warning('warning'))
      .finally(() => setLoading(false));
  };

  const getPlaceAutocomplete = (search: string, callback: (p: any) => void) => {
    return new Promise((resolve, reject) =>
      placeService
        .search(search, sessionId)
        .then((resultData: any) => {
          resolve(callback(resultData?.map((item: any) => item.entity) || []));
        })
        .catch(reject),
    );
  };

  useEffect(() => {
    setLoading(true);
    const menuService = new MenuService(legalizeApiUrl);

    MarketService.getInstance()
      .getDefaultPlaceId()
      .then((defaultPlaceId) => {
        if (defaultPlaceId) {
          menuService
            .getGroupsByBrandAndCategoryIds(marketId, defaultPlaceId, brandIds, categoryMerchIds)
            .then((resultData) => {
              if (resultData[0]?.entity?.skus) {
                const products = resultData[0]?.entity?.skus.filter(
                  (item: ISwag) => item.productId === productId,
                );

                const product = products[0] || {};
                const possibleValues = product.attributes.filter(
                  (item: IAttribute) => item.id === 'size',
                )[0]?.possibleValues;

                if (possibleValues) {
                  const _sizes: ISize[] = products.map((item: ISwag) => {
                    const size = item.attributes.filter((item) => item.id === 'size')[0]?.value;
                    return {
                      skuId: item.skuId,
                      quantity: item.quantity,
                      value: size,
                      label: size,
                    };
                  });
                  const filtred_sizes: ISize[] = [];
                  possibleValues.forEach((size: string) => {
                    const size_item = _sizes.filter((item) => item.value === size)[0];
                    if (size_item) {
                      filtred_sizes.push(size_item);
                    }
                  });
                  setSizes(filtred_sizes);
                  setSelectedSize(_sizes[0]);
                } else {
                  const _size: ISize = {
                    skuId: product.skuId,
                    quantity: product.quantity,
                    value: '',
                    label: '',
                  };
                  setSelectedSize(_size);
                }

                setSkus(products);
              }
              setLoading(false);
            });
        }
      });
  }, []);

  useEffect(() => {
    token &&
      clicApi
        .getLegalizeToken(token as string)
        .then((response) => response.json())
        .then((response) => {
          setAccessToken(response.token);
        });
  }, [token]);

  useEffect(() => {
    if (isEditAddress) {
      addressRef.current?.focus();
      const range = addressRef.current?.value.length;
      addressRef.current.selectionStart = addressRef.current.selectionEnd = range;
    }
  }, [isEditAddress]);

  const onFocusPhone = () => phoneRef.current?.querySelector('input').focus();

  const scrollToError = () => {
    setTimeout(() => {
      const errors = document.getElementsByClassName('form-error');

      errors.length && errors[0].scrollIntoView({ behavior: 'smooth' });
    }, 100);
  };

  return (
    <Formik
      initialValues={{
        address: null,
        phone: phone,
      }}
      validateOnChange
      onSubmit={onSubmit}
    >
      {({ isValid, setFieldValue, values }) => (
        <Form>
          <Preloader isActive={loading} />
          <div className={styles.body}>
            <div className={styles.infoBlock}>
              <Field name={'address'} validate={ValidationAlgorithms.validateString('Adderss')}>
                {({ field, meta }: FieldProps) => (
                  <div
                    className={classnames(
                      styles.card,
                      { [styles.active]: isEditAddress },
                      { 'form-error': meta.error },
                    )}
                  >
                    <div className={styles.cardTitle}>
                      <span>Delivery Address</span>
                    </div>
                    <div className={classnames(styles.cardBody)}>
                      <AsyncSelect
                        value={values.address}
                        isClearable={false}
                        styles={selectCustomStyle}
                        getOptionLabel={(option: any) => option.description}
                        cacheOptions
                        loadOptions={debouncedLoadOptions}
                        defaultOptions
                        placeholder={'Street Address'}
                        onChange={(option: any) => {
                          setFieldValue(field.name, option);
                          SwagAnalytics.enterAddress();
                        }}
                      />
                    </div>
                    {meta.error && meta.touched && (
                      <span className={styles.error}>{meta.error}</span>
                    )}
                    <div className={styles.cardTitle}>
                      <span>APT#</span>
                    </div>
                    <div className={styles.cardBody}>
                      <div>
                        <input
                          placeholder="enter here…"
                          value={apt}
                          onChange={(e) => setApt(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </Field>

              <Field name={'phone'} validate={ValidationAlgorithms.validatePhone}>
                {({ field, meta }: FieldProps) => (
                  <div className={classnames(styles.card, { 'form-error': meta.error })}>
                    <div className={styles.cardTitle}>
                      <span>Mobile (for delivery updates)</span>
                      <span className={styles.changeText} onClick={onFocusPhone}>
                        change
                      </span>
                    </div>
                    <div className={classnames(styles.cardBody)}>
                      <div ref={phoneRef}>
                        <InputMask {...field} mask="(999) 999-9999" />
                      </div>
                    </div>
                    {meta.error && meta.touched && (
                      <span className={styles.error}>{meta.error}</span>
                    )}
                  </div>
                )}
              </Field>
            </div>

            <div className={styles.cardBlock}>
              <div className={styles.card}>
                {skus.length && (
                  <Cart
                    quantity={quantity}
                    changeQuantity={setQuantity}
                    selectedSize={selectedSize}
                    changeSize={setSelectedSize}
                    skus={skus}
                    sizes={sizes}
                  />
                )}
              </div>
            </div>
          </div>
          <button
            type="submit"
            onClick={() => scrollToError()}
            className={`${styles.submitButton}`}
          >
            Place Order
          </button>
        </Form>
      )}
    </Formik>
  );
};

export default CheckoutForm;
