import { optionObject } from './../../checkout/components/variant-select/VariantSelect';
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

export default {
  container: (provided: any) => ({
    ...provided,
    marginTop: '0vw',
    border: '1px solid #CFCFCF',
  }),
  control: (provided: any, state: any) => ({
    ...provided,
    border: 'none',
    borderRadius: 0,
    outline: 0,
    minHeight: '58px',
    backgroundColor: state.selectProps.background || '#ffffff',
    '@media (max-width: 700px)': {
      minHeight: '38px',
    },
  }),
  option: (provided: any, state: any) => {
    return {
      ...provided,
      backgroundColor: 'white',
      color: '#626262',
      cursor: 'pointer',
      fontSize: '0.875rem',
      '&:first-of-type': state.selectProps.firstUnderline
        ? {
            borderBottom: 'solid 1px #b1b1b1',
            paddingTop: '20px',
            paddingBottom: '20px',
            marginBottom: '10px',
          }
        : {},

      ':hover': {
        backgroundColor: '#f2f2f2',
      },
      '@media (max-width: 500px)': {
        fontSize: '13px',
      },
    };
  },
  singleValue: (provided: any) => ({
    ...provided,
    fontFamily: 'Gotham',
    color: '#626262',
    fontSize: '0.875rem',
    '@media (max-width: 500px)': {
      fontSize: '13px',
    },
  }),
  placeholder: (provided: any) => ({
    ...provided,
    outline: 0,
    marginBottom: '20px',
    fontFamily: 'Gotham',
    fontSize: '14px',
    color: '#b1b1b1',
    '@media (max-width: 850px)': {
      marginRight: '20px',
    },
    '@media (max-width: 500px)': {
      fontSize: '13px',
    },
    textAlign: 'left',
  }),
  menu: (provided: any) => ({
    ...provided,
    marginTop: '0px',
    borderRadius: 0,
    '@media (max-width: 850px)': {
      marginTop: '5px',
    },
  }),
  indicatorSeparator: (provided: any) => ({
    ...provided,
    display: 'none',
  }),
};
