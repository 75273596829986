import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import { NotificationManager } from 'react-notifications';
import Spinner from 'react-spinkit';

import authService from 'core/services/authService/AuthService';
import { getDispensaries, registerPurchase } from '../../../../services';
import { alphabeticallySort } from 'services/helpers';

import useAuth from '../../../../hooks/useAuth';
import Textarea from '../membership-portal-form/Textarea';
import Modal from '../membership-portal-modal/Modal';
import Select from '../membership-portal-form/Select';
import Checkbox from '../membership-portal-form/Checkbox';

import styles from './CodeRegisterForm.module.scss';

export interface ConsumerFormValue {
  code: string;
  dispensary?: DispensaryObject;
  referralCode: string;
  notDispensary: boolean;
}

interface DispensaryObject {
  name: string;
  id: string;
}

const deliveryOption: DispensaryObject = {
  name: 'Click direct delivery',
  id: 'isDelivery',
};

const loadDispensaries = (inputValue: string, callback: (p: DispensaryObject[]) => void) => {
  getDispensaries(inputValue)
    .then((response) => response.json())
    .then((resultData) => {
      callback([deliveryOption, ...resultData.sort(alphabeticallySort)]);
    });
};

function validateCode(name: string): string | undefined {
  let error: string | undefined = '';
  if (!name) {
    error = 'Code is required!';
  }
  return error;
}
function validateDispensary(
  value: DispensaryObject,
  isActiveCheckbox: boolean,
): string | undefined {
  if (isActiveCheckbox) {
    return '';
  }
  let error: string | undefined = undefined;
  if (!value) {
    error = 'Dispansary is required!';
  }
  return error;
}

const CodeRegisterForm: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const {
    state: { isAuthenticated, isBasicRegistrationCompleted, profile },
  } = useAuth();

  const register = async (data: ConsumerFormValue, { resetForm }: any) => {
    const authState = authService.getInstance().getAuthState();
    if (isAuthenticated) {
      try {
        setIsLoading(true);
        let req_data: any = {
          code: data.code,
          referralCode: data.referralCode,
        };
        if (data.dispensary?.id === deliveryOption.id) {
          req_data.isDelivery = true;
        } else {
          req_data.companyHubspotId = data.dispensary?.id;
        }
        registerPurchase(req_data, authState.accessToken).then(async (res) => {
          setIsLoading(false);
          if (res.ok) {
            setShowModal(true);
            resetForm({
              code: '',
              dispensary: undefined,
              referralCode: '',
              notDispensary: false,
            });
          } else {
            const parsedResponse = await res.json();
            NotificationManager.warning(parsedResponse.message);
          }
        });
      } catch (e) {
        NotificationManager.warning(e);
      }
    } else {
      try {
        console.log(data);
      } catch (e) {
        NotificationManager.warning(e);
      }
    }
  };

  return (
    <div className={styles.codeForm}>
      <Formik
        initialValues={{
          code: '',
          dispensary: undefined,
          referralCode: '',
          notDispensary: false,
        }}
        validateOnChange
        onSubmit={register}
      >
        {({ isValid, setFieldValue, values, validateField }) => (
          <Form className={styles.codeRegisterForm}>
            {isLoading ? <Spinner name="line-spin-fade-loader" color="#FF3800" /> : null}

            <Textarea
              placeholder="ENTER CODE ON SHINY PROOF OF PURCHASE STICKER HERE!"
              type="text"
              value={values.code}
              validate={(p: string) => validateCode(p)}
              name="code"
              onChange={(value) => {
                setFieldValue('code', value);
                setTimeout(() => {
                  validateField('code');
                }, 0);
              }}
            />
            <Select
              name="dispensary"
              value={values.dispensary}
              validate={(p: DispensaryObject) => validateDispensary(p, values.notDispensary)}
              loadOptions={loadDispensaries}
              placeholder="WHERE DID YOU BUY CLICK?"
              isDisabled={values.notDispensary}
              onChange={setFieldValue}
              firstUnderline={true}
            />
            <div className={styles.checkboxBlock}>
              <Checkbox
                placeholder="I don’t remember"
                name="notDispensary"
                onClick={() =>
                  setTimeout(() => {
                    setFieldValue('dispensary', null);
                    setFieldValue('isDelivery', false);
                  }, 0)
                }
              />
            </div>

            <Textarea
              placeholder="Enter referral code"
              type="text"
              value={values.referralCode}
              name="referralCode"
              onChange={(value) => setFieldValue('referralCode', value)}
            />
            <button
              type="submit"
              className={`${styles.submitButton} ${!isValid && styles.disabled}`}
            >
              Register
            </button>
          </Form>
        )}
      </Formik>

      <Modal show={showModal} onHide={() => setShowModal(false)} />
    </div>
  );
};

export default CodeRegisterForm;
