import React from 'react';
import classnames from 'classnames';

import ProgressBarSlider from './ProgressBarSlider';

import styles from './ProgressBar.module.scss';

interface IProgressBarProps {
  points: number;
  className?: string;
}

function getSliderDataByPoints(points: number) {
  const levels = new Map()
      .set(points >= 0 && points < 300, {
        initialPercentage: 0,
        min: 0,
        max: 300,
        level: 1,
      })
      .set(points >= 300 && points < 2000, {
        initialPercentage: 33.3333,
        min: 301,
        max: 2000,
        level: 2,
      })
      .set(points >= 2000 && points < 7500, {
        initialPercentage: 66.6666,
        min: 2001,
        max: 7500,
        level: 3,
      })
      .set(points >= 7500, {
        initialPercentage: 100,
        min: 7500,
        max: Infinity,
        level: 4,
      }),
    currentLevel = levels.get(true),
    offsetPercentage = (100 * (points - currentLevel.min)) / (currentLevel.max - currentLevel.min),
    stabilizedPercentage =
      offsetPercentage < 33 ? 33 : offsetPercentage > 66 ? 66 : offsetPercentage;

  return {
    sliderPosition: currentLevel.initialPercentage + stabilizedPercentage / 3,
    level: currentLevel.level,
  };
}

function ProgressBar({ points, className }: IProgressBarProps) {
  const sliderData = getSliderDataByPoints(points);

  return (
    <div className={classnames(styles.progressBar, className)}>
      <div className={styles.progressBarLine}>
        <ProgressBarSlider
          points={points}
          level={sliderData.level}
          direction="left"
          position={sliderData.sliderPosition}
          className={classnames(styles.progressBarSlider, styles.progressBarSliderDesktop)}
        />
        <ProgressBarSlider
          points={points}
          level={sliderData.level}
          direction="top"
          position={sliderData.sliderPosition}
          className={classnames(styles.progressBarSlider, styles.progressBarSliderMobile)}
        />
      </div>
      <div className={styles.progressBarPins}>
        <div className={classnames(styles.progressBarPin1, styles.progressBarPinDescription)}>
          SIGN
          <br />
          UP
        </div>
        <div className={classnames(styles.progressBarPin2, styles.progressBarPinDescription)}>
          Points
          <br />
          <span>300</span>
        </div>
        <div className={classnames(styles.progressBarPin3, styles.progressBarPinDescription)}>
          Points
          <br />
          <span>2000</span>
        </div>
        <div className={classnames(styles.progressBarPin4, styles.progressBarPinDescription)}>
          Points
          <br />
          <span>7500</span>
        </div>
      </div>
    </div>
  );
}

export default ProgressBar;
