import React from 'react';
import classnames from 'classnames';

import { benefitLevels } from './benefitLevels';

import BenefitLevelTab from './BenefitLevelTab';
import BenefitLevelTabBody from './BenefitLevelTabBody';

import styles from './MembershipBenefits.module.scss';

function MembershipBenefits({ className = '' }: IMembershipBenefitsProps) {
  const [currentLevel, setCurrentLevel] = React.useState(0);

  return (
    <section className={classnames(styles.membershipBenefits, className)}>
      {benefitLevels.map((level, index) => (
        <link key={index} rel="preload" href={level.mainTriangle} as="image" />
      ))}

      <header className={styles.membershipBenefitsHeader}>
        <ul className={styles.benefitsLevels}>
          {benefitLevels.map((level, index) => (
            <BenefitLevelTab
              key={index}
              title={`LEVEL ${index + 1}`}
              color={level.color}
              isActive={index === currentLevel}
              onClick={() => setCurrentLevel(index)}
            />
          ))}
        </ul>
      </header>

      <div className={styles.membershipBenefitsBody}>
        {benefitLevels.map((benefitLevel, index) => (
          <BenefitLevelTabBody
            key={index}
            {...(benefitLevel as IBenefitLevel)}
            isActive={index === currentLevel}
            nextTab={() => setCurrentLevel(currentLevel + 1)}
            previousTab={() => setCurrentLevel(currentLevel - 1)}
          />
        ))}
      </div>
    </section>
  );
}

export default MembershipBenefits;
