import React from 'react';

import useAuth from 'hooks/useAuth';

import links, { message } from './links';
import Link from './Link';

import styles from './Social.module.scss';

const Social = () => {
  const inviteUrl = useAuth()?.state?.profile?.inviteUrl as string;

  return (
    <>
      <div className={styles.desktop}>
        {links.map((link, index) => (
          <Link key={index} {...link(inviteUrl)} />
        ))}
      </div>

      <button
        className={styles.mobile}
        type="button"
        onClick={async () => {
          try {
            await navigator.share({
              title: '',
              text: message,
              url: inviteUrl,
            });
          } catch (err) {}
        }}
      >
        share now
      </button>
    </>
  );
};

export default Social;
