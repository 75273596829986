import ClickAnalytics from './Analytic';

class Analytics {
  public track(event: string): void {
    return ClickAnalytics.getInstance().track(event);
  }

  private page(page: string): void {
    return ClickAnalytics.getInstance().page(page);
  }

  public carouselClick() {
    this.page('swag-carousel-click');
    this.track('Swag: Carousel Click');
  }

  public addToCart() {
    this.page('swag-add-to-cart');
    this.track('Swag: Add To Cart');
  }

  public enterAddress() {
    this.page('swag-enter-address');
    this.track('Swag: Enter Address');
  }

  public placeOrder() {
    this.page('swag-place-order');
    this.track('Swag: Place Order');
  }
}

export default new Analytics();
