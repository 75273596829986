import React from 'react';
import classnames from 'classnames';

import { FileDto } from 'services/interfaces';

import emptyAvatarUrl from 'images/user-placeholder.png';

import styles from './ProfileAvatar.module.scss';

interface IProfileAvatarProps {
  photo?: FileDto;
  size?: number;
  className?: string;
}

const ProfileAvatar = ({ photo, size = 180, className = '' }: IProfileAvatarProps) => (
  <div className={classnames(styles.avatar, className)} style={{ width: size, height: size }}>
    <div
      className={styles.avatarImage}
      style={{ background: `url(${photo?.url || emptyAvatarUrl}) center center no-repeat` }}
    />
  </div>
);

export default ProfileAvatar;
