import React from 'react';
import loadable from '@loadable/component';

import SwagAnalytics from 'core/framework/analytic/Swag';

const CarouselComponent = loadable(() => import('react-spring-3d-carousel'));

import { config } from 'react-spring';

import Arrow from '../arrow-button/Arrow';

import styles from './ProductDetails.module.scss';

interface IProductsSliderProps {
  products: any[];
  onChange(p: number): void;
  goToSlide: number;
}

function ProductsSlider({ products, onChange, goToSlide }: IProductsSliderProps) {
  const slides = products.map((item, index) => ({
    key: index,
    content: (
      <div className={styles.imgBlock} style={{ backgroundImage: `url(${item.imageUrl})` }}>
        {item.quantity === 0 && <div className={styles.soldOut}>Sold out</div>}
      </div>
    ),
    onClick: () => onChange(index),
  }));

  const onNext = () => {
    let step = goToSlide === products.length - 1 ? 0 : goToSlide + 1;
    onChange(step);
  };

  const onPrev = () => {
    let step = goToSlide === 0 ? products.length - 1 : goToSlide - 1;
    onChange(step);
  };

  const _config = config.slow;
  return products && CarouselComponent ? (
    <div className={styles.slider}>
      <CarouselComponent
        slides={slides}
        goToSlide={goToSlide}
        offsetRadius={2}
        showNavigation={false}
        animationConfig={_config}
      />
      <div className={styles.arrowBlock}>
        <Arrow
          onClick={() => {
            SwagAnalytics.carouselClick();
            onPrev();
          }}
          classes={styles.prevArrow}
        />
        <Arrow
          onClick={() => {
            SwagAnalytics.carouselClick();
            onNext();
          }}
          classes={styles.nextArrow}
          variant={{ direction: 'right' }}
        />
      </div>
    </div>
  ) : null;
}

export default ProductsSlider;
