import React, { useEffect } from 'react';
import { graphql } from 'gatsby';
import { Router } from '@reach/router';
import { isNode } from 'browser-or-node';
import { LoginCallback, Security } from '@okta/okta-react';
import { isBrowser } from 'browser-or-node';

import InviteCustomer from 'components/account/invite-customer/InviteCustomer';
import authService from 'core/services/authService/AuthService';
import Login from 'modules/auth/login';
import PrivateRoute from 'modules/auth/privateRoute';
import SocialSignup from 'modules/auth/socialSignup';
import ProductInfo from 'components/account/product-info/ProductInfo';
import SignUp from 'modules/auth/signup';
import CompleteSignup from 'modules/auth/complateSignup';
import MembershipPortal from 'components/account/mambership-portal/MembershipPortal';
import Checkout from '../components/account/checkout/Checkout';
import Success from '../components/account/checkout/Success';
import AuthFlowLayout from 'components/layouts/authFlowLayout/AuthFlowLayout';

import StorageService from 'core/services/storageService/StorageService';

const isSessionStorage = (isBrowser && window.sessionStorage) || undefined;
const redirectUrlService = new StorageService(isSessionStorage, 'redirect_url');
interface IAccountProps {
  data: {
    [imgKey: string]: {
      childImageSharp: {
        fluid: string;
      };
    };
  };
}

const Account: React.FC<IAccountProps> = ({ data }) => {
  let redirect_url = redirectUrlService.read();

  useEffect(() => {
    if (!redirect_url) {
      redirect_url = window.location.href.substring(window.location.origin.length);
      redirectUrlService.write(redirect_url);
    }
  }, []);

  return isNode ? null : (
    <Security authService={authService.getInstance()}>
      <Router basepath="/account">
        <LoginCallback path="/implicit/callback" />
        <Login
          path="/login"
          layoutComponent={AuthFlowLayout}
          signInLink="/account/join"
          isAuthenticatedRedirectLink={redirect_url || '/account'}
        />
        <SignUp
          path="/signup"
          backLink="/account/join"
          loginLink="/account/login"
          complateRegistrationLink="/account/complete"
        />
        <CompleteSignup
          path="/complete"
          backLink="/account/join"
          signupLink="/account/signup"
          complateRegistrationLink={redirect_url || '/account'}
        />
        <SocialSignup
          path="/join"
          loginLink="/account/login"
          signUpLink="/account/signup"
          layoutComponent={AuthFlowLayout}
          isAuthenticatedRedirectLink="/account"
        />

        <PrivateRoute
          default
          path="/membership"
          loginRedirectLink="/account/login"
          signupRedirectLink="/account/signup"
          fullRegistrationComplatedRedirectLink="/account/complete"
          component={MembershipPortal}
          bgImage={data.membershipBackground.childImageSharp.fluid}
          friendsShareBackground={data.friendsShareBackground.childImageSharp.fluid}
        />
        <PrivateRoute
          exact
          path="/product-info"
          loginRedirectLink="/account/login"
          signupRedirectLink="/account/signup"
          fullRegistrationComplatedRedirectLink="/account/complete"
          component={ProductInfo}
          chillImage={data.chillProductImg.childImageSharp.fluid}
          dreamImage={data.dreamProductImg.childImageSharp.fluid}
          goImage={data.goProductImg.childImageSharp.fluid}
          restoreImage={data.restoreProductImg.childImageSharp.fluid}
        />
        <PrivateRoute exact path="/checkout" component={Checkout} />
        <PrivateRoute exact path="/success" component={Success} />
        <PrivateRoute exact path="/invite-customer" component={InviteCustomer} />
      </Router>
    </Security>
  );
};

export default Account;

export const query = graphql`
  query {
    membershipBackground: file(relativePath: { eq: "bg-membership-header.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 1000, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    friendsShareBackground: file(relativePath: { eq: "friends-share-bg.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 1000, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    comingSoonBackground: file(relativePath: { eq: "confirmation_bg.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 1000, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    profileBackground: file(relativePath: { eq: "profile_bg.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 1000, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    chillProductImg: file(relativePath: { eq: "chill_product_image.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 500, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    dreamProductImg: file(relativePath: { eq: "dream_product_image.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 500, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    goProductImg: file(relativePath: { eq: "go_product_image.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 500, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    restoreProductImg: file(relativePath: { eq: "restore_product_image.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 500, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
