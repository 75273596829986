import React from 'react';
import { Field, FieldProps } from 'formik';
import styles from './Form.module.scss';

interface Props {
  value?: string;
  placeholder?: string;
  name: string;
  onClick?: any;
}

const Checkbox: React.FC<Props> = (props: Props) => {
  const { name, placeholder, onClick } = props;

  return (
    <Field name={name} type="checkbox">
      {({ field }: FieldProps) => (
        <div className={styles.checkbox}>
          <label>
            <input {...field} onClick={onClick} type="checkbox" />
            {placeholder}
          </label>
        </div>
      )}
    </Field>
  );
};

export default Checkbox;
