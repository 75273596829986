import React from 'react';
import { FluidObject } from 'gatsby-image';
import { navigate } from 'gatsby';

import Layout from 'components/layouts/main/Layout';
import ValidatedAccount from '../ValidatedAccount';
import CheckoutForm from './components/form/Form';
import Header from 'components/shopHeader/Header';

import styles from './Checkout.module.scss';

interface Props {
  location: Location;
  bgImage: FluidObject;
  friendsShareBackground: FluidObject;
}

const Checkout: React.FC<Props> = ({}) => {
  return (
    <Layout isSideMenu={false} location={location.pathname} className={styles.layout}>
      <Header
        backImageColor={'#FF3800'}
        className={styles.logoHeader}
        printLocation={false}
        goBack={() => navigate('/account#swag')}
      />
      <div className={styles.background} />
      <section className={styles.checkout}>
        <header className={styles.title}>CHECK OUT</header>
        <CheckoutForm />
      </section>
    </Layout>
  );
};

const AccountValidate = ({ location, bgImage, friendsShareBackground }: Props) => (
  <ValidatedAccount
    exact
    path="/account/checkout"
    component={Checkout}
    location={location}
    bgImage={bgImage}
    friendsShareBackground={friendsShareBackground}
  />
);

export default AccountValidate;
