import classnames from 'classnames';
import { FluidObject } from 'gatsby-image';
import Img from 'gatsby-image/withIEPolyfill';
import React from 'react';

import styles from './ShareWithFriends.module.scss';

interface IMembershipAboutProps {
  className?: string;
  friendsShareBackground: FluidObject;
}

function ShareWithFriendsBackground({ className, friendsShareBackground }: IMembershipAboutProps) {
  return (
    <section className={classnames(styles.shareWithFriendsBackground, className)}>
      <Img fadeIn={false} fluid={friendsShareBackground} objectPosition="0% 50%" />
    </section>
  );
}

export default ShareWithFriendsBackground;
