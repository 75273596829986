import React, { useState } from 'react';
import classnames from 'classnames';
import redArrow from 'images/red-arrow.svg';
import styles from './Arrow.module.scss';

interface Props {
  className?: string;
  style?: object;
  classes?: string;
  variant?: VariantProps;
  onClick?: any;
}

interface VariantProps {
  color?: 'red';
  direction?: 'left' | 'right' | 'top' | 'bottom';
  size?: 'small';
}
function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
function Arrow({ className, style, onClick, classes, variant }: Props) {
  const direction: string = capitalizeFirstLetter(variant?.direction || 'default');
  const color: string = capitalizeFirstLetter(variant?.color || 'default');
  const size: string = capitalizeFirstLetter(variant?.size || 'default');
  return (
    <div
      className={classnames(
        className,
        classes,
        styles.arrow,
        styles[`direction${direction}`],
        styles[`color${color}`],
        styles[`size${size}`],
      )}
      style={{ ...style }}
      onClick={onClick}
    >
      <img src={redArrow} />
    </div>
  );
}

export default Arrow;
