import React from 'react';
import classnames from 'classnames';

import styles from './MembershipBenefits.module.scss';

function BenefitTriangle({ color, title, description, position }: IBenefitTriangleProps) {
  return (
    <div className={classnames(styles.benefitTriangle, styles[position])}>
      <svg
        width="140"
        height="132"
        viewBox="0 0 140 132"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={styles.benefitTriangleBackground}
      >
        <path
          d="M18.499 131.791H19.7834C35.0977 129.106 52.3881 127.661 70.5676 127.661C88.7472 127.661 106.037 129.106 121.352 131.791C135.678 131.791 144.669 115.581 137.456 102.676L137.16 102.16C127.28 89.5639 117.499 74.6968 108.31 58.2811C99.2202 41.7621 91.81 25.4496 86.3759 10.2728L86.0795 9.75653C78.9658 -3.25218 60.9838 -3.25218 53.8701 9.75653L53.0797 11.2019C47.5468 26.3788 40.2354 42.6913 31.1457 59.2103C22.0559 75.626 12.2745 90.4931 2.39431 102.986C-4.52182 115.788 4.37035 131.791 18.499 131.791Z"
          fill={color}
        />
      </svg>
      <div className={styles.benefitTriangleTitle}>{title}</div>
      <div className={styles.benefitTriangleDescription} style={{ color }}>
        {description}
      </div>
    </div>
  );
}

export default BenefitTriangle;
