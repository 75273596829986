import React, { Component } from 'react';
import InputMask from 'react-input-mask';
import validator from 'validator';
import Spinner from 'react-spinkit';

import authService from 'core/services/authService/AuthService';
import { inviteCustomer } from 'services';
import Layout from 'components/layouts/main/Layout';
import styles from './InviteCustomer.module.scss';
import bgTriangleChill from 'images/Chill.svg';
import ValidatedAccount from '../ValidatedAccount';
import validEmailRegex from '../helpers/email-reg-ex';
import { NotificationContainer, NotificationManager } from 'react-notifications';

const AccountValidate = ({ location }) => {
  return (
    <ValidatedAccount
      exact
      path="/account/invite-customer"
      component={InviteCustomer}
      location={location}
    ></ValidatedAccount>
  );
};

class InviteCustomer extends Component<any> {
  state: any;

  constructor(props) {
    super(props);

    this.state = {
      errors: {
        phone: '',
        email: '',
      },
      phone: '',
      email: '',
      isLoading: false,
    };
  }

  handleInputChange = (name, value) => {
    const errors = this.state.errors;

    switch (name) {
      case 'phone':
        errors.phone =
          value.length > 0 && !validator.isMobilePhone(value, ['en-US'])
            ? 'Phone is not valid!'
            : '';
        break;
      case 'email':
        errors.email =
          value.length === 0 || validEmailRegex.test(value) ? '' : 'Email is not valid!';
        break;
      default:
        break;
    }

    this.setState({ errors, [name]: value });
  };

  inviteCustomer = (e) => {
    e.preventDefault();
    const errors = this.state.errors;

    if (this.state.phone.length < 1 && this.state.email.length < 1) {
      NotificationManager.warning('Either email or phone is required!');
      return false;
    }

    const authState = authService.getInstance().getAuthState();

    const data = {
      email: this.state.email,
      phone: this.state.phone.replace('(', '').replace(')', '').replace('-', '').replace(/ /g, ''),
    };

    this.setState({
      isLoading: true,
    });

    inviteCustomer(data, authState.accessToken).then((res) => {
      this.setState({
        isLoading: false,
      });
      if (res.ok) {
        NotificationManager.success('Customer Invited');
        this.setState({
          email: '',
          phone: '',
        });
      }
    });
  };

  render() {
    const { errors, isLoading, phone, email } = this.state;

    return (
      <Layout location={this.props.location.pathname}>
        {isLoading ? <Spinner name="line-spin-fade-loader" color="#FF3800" /> : null}
        <NotificationContainer />

        <section className={styles.screenBox}>
          <div
            className={styles.triangleBackground}
            style={{ backgroundImage: `url(${bgTriangleChill})` }}
          ></div>
          <h1>Invite Customer</h1>
          <form className={styles.inviteForm} onSubmit={this.inviteCustomer}>
            <label>
              Email
              <input
                placeholder="Enter email"
                type="text"
                name="email"
                value={email}
                onChange={(e) => this.handleInputChange(e.target.name, e.target.value)}
              />
            </label>
            {errors && errors.email.length > 0 && (
              <span className={styles.error}>{errors.email}</span>
            )}

            <label>
              <span>Phone number</span>
              <InputMask
                placeholder="(000) 000-0000"
                type="tel"
                name="phone"
                mask="(999) 999-9999"
                value={phone}
                onChange={(e) => this.handleInputChange(e.target.name, e.target.value)}
              />
            </label>
            {errors.phone.length > 0 && <span className={styles.error}>{errors.phone}</span>}

            <button type="submit" className={styles.submitButton}>
              Send Invite
            </button>
          </form>
        </section>
      </Layout>
    );
  }
}
export default AccountValidate;
