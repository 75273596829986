import React from 'react';
import classnames from 'classnames';
import { NotificationManager } from 'react-notifications';

import SwagAnalytics from 'core/framework/analytic/Swag';
import useRewards from 'hooks/useRewards';

import styles from './ProductDetails.module.scss';

interface Props {
  product: any;
  onAddToCart(id: any): void;
}

function ProductDescription({ product, onAddToCart }: Props) {
  const { awardedPoints } = useRewards();

  const onAddToCartClick = () => {
    if (awardedPoints < product.price) {
      NotificationManager.warning('Not enough points');
    } else {
      SwagAnalytics.addToCart();
      onAddToCart(product);
    }
  };

  let details = product.attributes.filter((item) => item.id === 'product_details')[0]?.value;

  return (
    <div className={styles.details}>
      <div className={styles.detailsBase}>
        <div className={styles.productName}>{product.name}</div>
        <div className={styles.productPointAndButtonContainer}>
          <div className={styles.productPrice}>{product.price} Points</div>
          <button
            onClick={onAddToCartClick}
            className={classnames(styles.button, { [styles.disabled]: !product.quantity })}
          >
            Add to cart
          </button>
        </div>
      </div>
      <div className={styles.detailsDescription}>
        <div className={styles.descriptionSection}>
          <div className={styles.sectionTitle}>About</div>
          <div className={styles.sectionText}>{product.description}</div>
        </div>
        <div className={styles.descriptionSection}>
          <div className={styles.sectionTitle}>Product details</div>
          <div className={styles.sectionText} dangerouslySetInnerHTML={{ __html: details }}></div>
        </div>
      </div>
    </div>
  );
}

export default ProductDescription;
