import React, { useEffect, useState } from 'react';
import { Field, FieldProps } from 'formik';
import AsyncSelect from 'react-select/async';

import selectCustomStyles from '../membership-portal-form/select-custom-style';

import styles from './Form.module.scss';

interface Props {
  value?: any;
  placeholder?: string;
  name: string;
  onChange: any;
  validate: any;
  loadOptions: any;
  isDisabled?: boolean;
  Required?: boolean;
  firstUnderline?: boolean;
  label?: string;
  bgColor?: string;
}

interface optionObject {
  name: string;
  value: string;
}

const FormField: React.FC<Props> = (props) => {
  const {
    name,
    validate,
    isDisabled,
    value,
    placeholder,
    onChange,
    loadOptions,
    label,
    Required,
    bgColor,
    firstUnderline = false,
  } = props;

  const [selectKey, setSelectKey] = useState(value?.id || null);

  useEffect(() => {
    setSelectKey(value?.id || null);
  }, [value]);

  return (
    <Field name={name} validate={validate}>
      {({ meta }: FieldProps) => (
        <div className={styles.searchDropdown}>
          {label && (
            <span className={styles.formLabel}>
              {label}
              {Required && '*'}
            </span>
          )}
          <AsyncSelect
            key={selectKey}
            background={bgColor}
            value={value}
            isClearable
            styles={selectCustomStyles}
            getOptionLabel={(option: optionObject) => option.name}
            loadOptions={loadOptions}
            defaultOptions
            placeholder={placeholder}
            isDisabled={isDisabled}
            firstUnderline={firstUnderline}
            onChange={(option: optionObject) => onChange(name, option)}
          />
          {meta.error && <span className={styles.error}>{meta.error}</span>}
        </div>
      )}
    </Field>
  );
};

export default FormField;
