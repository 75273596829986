import { navigate } from 'gatsby';
import classnames from 'classnames';
import React, { useRef, useEffect } from 'react';
import { FluidObject } from 'gatsby-image';
import { NotificationContainer } from 'react-notifications';
import { isBrowser } from 'browser-or-node';

import Layout from 'components/layouts/main/Layout';
import ValidatedAccount from '../ValidatedAccount';
import CodeRegister from './code-register-form/CodeRegister';
import MembershipHeader from './membership-header/MembershipHeader';
import MembershipShop from './membership-shop/MembershipShop';
import ShareWithFriends from './share-with-friends/ShareWithFriends';
import ShareWithFriendsBackground from './share-with-friends/ShareWithFriendsBackground';
import MembershipEditProfile from './membership-profile-edit/MemdershipProfileEdit';
import MembershipAbout from './membership-about/MembershipAbout';
import MembershipBenefits from './membership-benefits/MembershipBenefits';
import MembershipRedemtion from './membership-redemtion/MembershipRedemtion';

import useAuth from 'hooks/useAuth';
import { RewardsUserType } from 'services/enums';
import StorageService from 'core/services/storageService/StorageService';

import styles from './MembershipPortal.module.scss';

interface Props {
  location: Location;
  bgImage: FluidObject;
  friendsShareBackground: FluidObject;
}

const scrollTo = (element: HTMLElement | null, hash: string) => {
  addHashToUrl(hash);
  setTimeout(() => {
    element?.scrollIntoView({ behavior: 'smooth' });
  }, 10);
};

const addHashToUrl = (hash: string) => (window.location.hash = hash);

const isSessionStorage = (isBrowser && window.sessionStorage) || undefined;
const redirectUrlService = new StorageService(isSessionStorage, 'redirect_url');

const MembershipPortal: React.FC<Props> = ({ location, bgImage, friendsShareBackground }) => {
  const { profile } = useAuth().state;
  const { fetchProfile } = useAuth();

  const RegisterRef = useRef<HTMLElement>(null);
  const ShareRef = useRef<HTMLElement>(null);
  const ProfileRef = useRef<HTMLElement>(null);
  const AboutRef = useRef<HTMLElement>(null);
  const RedemtionRef = useRef<HTMLElement>(null);
  const ShopRef = useRef<HTMLElement>(null);

  const availableLinks =
    profile?.rewardsUserType !== RewardsUserType.consumer
      ? [
          {
            name: 'PRODUCT INFO',
            onClick: () => {
              navigate('/account/product-info');
            },
          },
          {
            name: 'INVITE',
            onClick: () => {
              navigate('/account/invite-customer');
            },
          },
          {
            name: 'PROFILE',
            onClick: () => {
              scrollTo(ProfileRef.current, 'profile');
            },
          },
        ]
      : [
          {
            name: 'REGISTER',
            onClick: () => {
              scrollTo(RegisterRef.current, 'register');
            },
          },
          {
            name: 'SHARE',
            onClick: () => {
              scrollTo(ShareRef.current, 'share');
            },
          },
          {
            name: 'ABOUT',
            onClick: () => {
              scrollTo(AboutRef.current, 'about');
            },
          },
          {
            name: 'REWARDS',
            onClick: () => {
              scrollTo(ShopRef.current, 'rewards');
            },
          },
          {
            name: 'PROFILE',
            onClick: function () {
              scrollTo(ProfileRef.current, 'profile');
            },
          },
        ];

  useEffect(() => {
    if (!profile?.zip) {
      navigate('/account/complete');
    }
    fetchProfile();

    if (window.location.hash) {
      let hash = window.location.hash.substring(1);
      let link = availableLinks.find(
        (element) => element.name.toLocaleLowerCase() === hash.toLocaleLowerCase(),
      );
      link && link.onClick();
    }

    if (redirectUrlService.read()) {
      redirectUrlService.write('');
    }
  }, []);

  return (
    <Layout location={location.pathname} className={styles.layout} availableLinks={availableLinks}>
      <MembershipHeader
        goToProfile={() => scrollTo(ProfileRef.current, 'profile')}
        refLinks={{ register: RegisterRef, earnPoints: ShareRef, about: AboutRef, swag: ShopRef }}
        bgImage={bgImage}
        className={styles.grid}
      />
      <CodeRegister setRef={RegisterRef} className={styles.grid} />
      <NotificationContainer />

      <ShareWithFriends setRef={ShareRef} className={classnames(styles.zIndex2, styles.grid)} />
      <ShareWithFriendsBackground
        friendsShareBackground={friendsShareBackground}
        className={styles.zIndex1}
      />

      <MembershipShop setRef={ShopRef} className={styles.grid} />

      <MembershipAbout setRef={AboutRef} className={styles.grid} />

      <MembershipBenefits className={styles.zIndex2} />

      <MembershipRedemtion setRef={RedemtionRef} className={styles.grid} />

      <MembershipEditProfile setRef={ProfileRef} className={styles.grid} />

      <NotificationContainer />
    </Layout>
  );
};

const AccountValidate = ({ location, rewardsImages, bgImage, friendsShareBackground }: Props) => (
  <ValidatedAccount
    exact
    path="/account/membership"
    component={MembershipPortal}
    location={location}
    rewardsImages={rewardsImages}
    bgImage={bgImage}
    friendsShareBackground={friendsShareBackground}
  />
);

export default AccountValidate;
