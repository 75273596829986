import React, { useState, useEffect } from 'react';
import { isBrowser } from 'browser-or-node';
import { MenuService, MarketService } from '@legalize/sdk';
import { navigate } from 'gatsby';

import StorageService from 'core/services/storageService/StorageService';
import { marketId, brandIds, categoryMerchIds, legalizeApiUrl } from 'services/config';

import ProductsSlider from './ProductsSlider';
import ProductDescription from './ProductDescription';

import styles from './ProductDetails.module.scss';

interface ISwag {
  skuId: number;
  name: string;
  imageUrl: string;
  quantity: number;
  productId: number;
  price: number;
  attributes: IAttribute[];
}

interface IAttribute {
  id: string;
  name: string;
  value: string;
  isVisible?: boolean;
  possibleValues: string;
}

function ProductDetails() {
  const [activeProductIndex, setActiveProductIndex] = useState(0);
  const [products, setProducts] = useState<ISwag[] | []>([]);
  const menuService = new MenuService(legalizeApiUrl);
  const productStorage = new StorageService(
    (isBrowser && window.sessionStorage) || undefined,
    'productId',
  );

  if (productStorage.read()) {
    productStorage.write('');
  }

  useEffect(() => {
    const marketService = new MarketService(legalizeApiUrl);

    marketService.getMarketInfo(marketId).then((res) => {
      if (res.entity.defaultPlaceId) {
        menuService;
        menuService
          .getGroupsByBrandAndCategoryIds(
            marketId,
            res.entity.defaultPlaceId,
            brandIds,
            categoryMerchIds,
          )
          .then((resultData: any) => {
            if (resultData[0]?.entity?.skus) {
              let product_ids: number[] = [];
              let skus: any[] = [];
              resultData[0]?.entity?.skus.forEach((item: ISwag) => {
                if (!product_ids.includes(item.productId)) {
                  product_ids.push(item.productId);
                  skus.push(item);
                }
              });
              setProducts(skus);
            }
          });
      }
    });
  }, []);

  const onAddToCart = (product: ISwag) => {
    productStorage.write(product.productId);
    navigate('/account/checkout');
  };

  return products.length ? (
    <div className={styles.product}>
      <ProductsSlider
        goToSlide={activeProductIndex}
        products={products}
        onChange={(index) => setActiveProductIndex(index)}
      />
      <ProductDescription onAddToCart={onAddToCart} product={products[activeProductIndex]} />
    </div>
  ) : null;
}

export default ProductDetails;
