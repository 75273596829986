import React from 'react';
import classnames from 'classnames';
import Spinner from 'react-spinkit';
import { NotificationManager } from 'react-notifications';

import { uploadFile } from 'services';
import { Profile } from 'services/interfaces';
import useAuth from 'hooks/useAuth';
import useRewards from 'hooks/useRewards';

import ProfileAvatar from './profile-avatar/ProfileAvatar';

import styles from './ProfileWidget.module.scss';

interface IProfileWidgetProps {
  className?: string;
  goToEditProfile(): void;
}

function getLevelByPoints(points: number) {
  return new Map()
    .set(points >= 0 && points < 300, 1)
    .set(points >= 300 && points < 2000, 2)
    .set(points >= 2000 && points < 7500, 3)
    .set(points >= 7500, 4)
    .get(true);
}

function ProfileWidget({ goToEditProfile, className = '' }: IProfileWidgetProps) {
  const inputFileRef = React.useRef<HTMLInputElement>(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const { state, saveProfile } = useAuth();
  const { awardedPoints, availablePoints } = useRewards();
  const profile = state.profile as Profile;

  const onChangeInput = () => {
    const data = new FormData();
    const files = inputFileRef.current?.files || [];

    if (files.length) {
      data.append('file', files[0]);

      setIsLoading(true);

      uploadFile(data, state.token ?? '')
        .then((response) => response.json())
        .then((photo) =>
          saveProfile({
            ...(profile as Profile),
            photoId: +photo.id,
            photo,
          }),
        )
        .then(() => NotificationManager.success('Success update photo'))
        .catch(NotificationManager.warning)
        .finally(() => setIsLoading(false));
    }
  };

  return (
    <section className={classnames(styles.profileWidget, className)}>
      <div className={styles.personalInfo}>
        {isLoading ? <Spinner name="line-spin-fade-loader" color="#FF3800" /> : null}
        <label>
          <ProfileAvatar photo={profile.photo} className={styles.personalInfoAvatar} />
          <input
            type="file"
            ref={inputFileRef}
            style={{ display: 'none' }}
            accept="image/*"
            onChange={onChangeInput}
          />
        </label>
        <div className={styles.personalInfoUserName}>
          <p className={styles.personalInfoGreeting}>
            Welcome, <span>{profile.firstName}</span>
          </p>
          <button
            type="button"
            disabled={isLoading}
            className={styles.personalInfoLink}
            onClick={goToEditProfile}
          >
            Edit Profile
          </button>
        </div>
      </div>
      <div className={styles.pointsInfo}>
        <div className={styles.points}>
          <p className={styles.pointsTitle}>
            Available Points <span>{availablePoints}</span>
          </p>
        </div>
        <div className={styles.level}>
          <div className={styles.levelTitle}>you are on</div>
          <div className={styles.levelValue}>level {getLevelByPoints(awardedPoints)}</div>
        </div>
      </div>
    </section>
  );
}

ProfileWidget.defaultProps = {
  linkProps: {
    title: '',
    onClick() {},
  },
};

export default ProfileWidget;
