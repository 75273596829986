import React from 'react';
import { isNode } from 'browser-or-node';
import { Link } from 'gatsby';

import styles from './AuthFlowLayout.module.scss';
import videoBackgound from '../../../videos/BG Animation.mp4';
import textLogo from '../../../images/Logo-Type-White.svg';

export default ({ children, isSocialSignUp }: any) => {
  if (isNode) return null;

  return (
    <section className={styles.wrapper + ' ' + (isSocialSignUp ? 'okta-social-only' : '')}>
      <video playsInline autoPlay muted loop className={styles.videoBackgound}>
        <source src={videoBackgound} type="video/mp4"></source>
      </video>
      <Link className={styles.logo} to="https://clickspray.com">
        <img src={textLogo} alt="Text Logo"></img>
      </Link>
      <Link to="https://clickspray.com" className={styles.backButton}>
        <span style={{ color: 'white' }} className="material-icons">
          arrow_back
        </span>
      </Link>
      {children}
    </section>
  );
};
