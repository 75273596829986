import React from 'react';
import AsyncSelect from 'react-select/async';

import styles from './VariantSelect.module.scss';
import selectCustomStyles from './select-custom-style';

interface Props {
  options: optionObject[];
  label: string;
  defaultText: string;
  onChange(options: any): void;
  value: any;
}

export interface optionObject {
  label: string;
  value: string;
}
const CaretDownIcon = () => {
  return null;
};

const VariantSelect = ({ options, label, defaultText, onChange, value }: Props) => {
  return (
    <div className={styles.variantSelect}>
      {!!options.length ? (
        <AsyncSelect
          value={value}
          isSearchable={false}
          defaultOptions={options}
          styles={selectCustomStyles}
          isClearable={false}
          onChange={(option) => onChange(option)}
          placeholder={label}
        />
      ) : (
        <span>{defaultText}</span>
      )}
    </div>
  );
};

export default VariantSelect;
