import React, { useState } from 'react';
import classnames from 'classnames';
import { Form, Formik } from 'formik';
import Spinner from 'react-spinkit';
import { NotificationManager } from 'react-notifications';

import states from 'components/account/profile/usa-states';
import useAuth from 'hooks/useAuth';
import { getDispensaries, updateProfile } from 'services';
import { Gender } from 'services/enums';
import { Profile } from 'services/interfaces';
import { DOBToDate, dateToDOB } from 'services/helpers';

import FormField from 'components/account/mambership-portal/membership-portal-form/FormField';
import Select from 'components/account/mambership-portal/membership-portal-form/Select';
import Radio from 'components/account/mambership-portal/membership-portal-form/Radio';
import Password from 'components/account/mambership-portal/membership-portal-form/Password';

import UserIsBudtender from 'components/shared/userIs/UserIsBudtender';

import * as ValidationAlgorithms from 'services/helpers/ValidationAlgorithms';

import styles from './MembershipProfileEdit.module.scss';

const GENDERS = [
  {
    label: 'Male',
    value: Gender.MALE,
  },
  {
    label: 'Female',
    value: Gender.FEMALE,
  },
];

const loadStates = (inputValue: string) => {
  const stateObjects: StateObject[] = states.map((name) => ({
    name,
    value: name.toLowerCase(),
  }));

  return Promise.resolve(
    inputValue
      ? stateObjects.filter((p) => p.value.startsWith(inputValue.toLowerCase()))
      : stateObjects,
  );
};

const loadDispensaries = (inputValue: string) =>
  new Promise((resolve) =>
    getDispensaries(inputValue)
      .then((response) => response.json())
      .then(resolve)
      .catch(() => resolve([])),
  );

const convertPhone = (phone?: string) => (phone ? phone.replace(/[\(\) -]/g, '') : '');

const MembershipEditProfileForm: React.FC<IMembershipEditProfileFormProps> = ({ className }) => {
  const authState = useAuth().state;
  const profile = authState.profile as Profile;
  const accessToken = authState.token || '';
  const [isLoading, setIsLoading] = useState(false);
  const inviteCode = profile?.inviteCode || '';
  const initialProfile: ProfileForm = {
    ...profile,
    dob: dateToDOB(profile.dob),
    inviteCode: inviteCode ? inviteCode : '',
    state: profile.state ? { name: profile.state, value: profile.state } : undefined,
  };

  const onSubmit = (data: ProfileForm) => {
    setIsLoading(true);
    updateProfile(
      {
        ...profile,
        ...data,
        phone: convertPhone(data.phone),
        state: data.state?.value,
        dob: DOBToDate(data.dob),
      },
      accessToken,
    )
      .then(() => NotificationManager.success('Saved'))
      .catch(() => NotificationManager.warning('Not saved'))
      .finally(() => setIsLoading(false));
  };

  return (
    <div className={classnames(styles.profileFormContainer, className)}>
      {isLoading && <Spinner name="line-spin-fade-loader" color="#FF3800" />}

      <Formik initialValues={initialProfile} validateOnChange onSubmit={onSubmit}>
        {({ isValid, setFieldValue, values }) => (
          <Form className={styles.profileForm} autoComplete="off">
            {isLoading && <Spinner name="line-spin-fade-loader" color="#FF3800" />}

            <div className={styles.fieldColumns}>
              <div className={styles.col}>
                <UserIsBudtender>
                  <Select
                    label="Dispensary"
                    name="company"
                    value={values.company}
                    validate={ValidationAlgorithms.validateDispensary}
                    loadOptions={loadDispensaries}
                    placeholder="Dispensary"
                    onChange={setFieldValue}
                    Required
                    bgColor="#F9F9F9"
                  />
                </UserIsBudtender>

                <FormField
                  label="First Name"
                  placeholder="First Name"
                  type="text"
                  validate={ValidationAlgorithms.validateString('First name')}
                  name="firstName"
                  Required
                  isEditIcon
                />

                <FormField
                  label="Last Name"
                  placeholder="Last Name"
                  type="text"
                  validate={ValidationAlgorithms.validateString('Last name')}
                  name="lastName"
                  Required
                  isEditIcon
                />

                <FormField
                  label="Email"
                  placeholder="Email"
                  type="email"
                  validate={ValidationAlgorithms.validateEmail}
                  name="email"
                  Required
                  isEditIcon
                />

                <Password />

                <FormField
                  label="DOB"
                  validate={ValidationAlgorithms.validateDob}
                  name="dob"
                  placeholder="DOB MM/DD/YYYY"
                  type="text"
                  maskChar="_"
                  mask="99-99-9999"
                  Required
                  isEditIcon
                />

                <Radio
                  name="gender"
                  label="Gender"
                  validate={ValidationAlgorithms.validateGender}
                  values={GENDERS}
                />
              </div>

              <div className={styles.col}>
                <FormField
                  label="Phone Number"
                  placeholder="Phone Number"
                  type="tel"
                  validate={ValidationAlgorithms.validatePhone}
                  name="phone"
                  mask="(999) 999-9999"
                  Required
                  isEditIcon
                />

                <FormField
                  label="Street address"
                  validate={ValidationAlgorithms.validateString('Street address')}
                  name="streetAddress"
                  Required
                  placeholder="Street address"
                  type="text"
                  isEditIcon
                />

                <FormField
                  label="Zip code"
                  validate={ValidationAlgorithms.validateZip}
                  name="zip"
                  Required
                  placeholder="Zip code"
                  type="number"
                  maxLength={5}
                  isEditIcon
                />

                <FormField
                  label="city"
                  validate={ValidationAlgorithms.validateString('city')}
                  name="city"
                  Required
                  placeholder="city"
                  type="text"
                  isEditIcon
                />

                <Select
                  label="state"
                  name="state"
                  value={values.state}
                  validate={ValidationAlgorithms.validateString('State')}
                  loadOptions={loadStates}
                  placeholder="state"
                  onChange={setFieldValue}
                  Required
                  bgColor="#F9F9F9"
                />

                <FormField
                  label="Referral Code"
                  name="inviteCode"
                  placeholder="referral code"
                  type="text"
                  isEditIcon
                  isCopyIcon
                />
              </div>
            </div>

            <button
              type="submit"
              className={classnames(styles.submitButton, { [styles.disabled]: !isValid })}
            >
              Update Profile
            </button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default MembershipEditProfileForm;
