import React from 'react';
import classnames from 'classnames';

import styles from './MembershipBenefits.module.scss';

const BenefitLevelTab = ({ color, isActive, title, onClick }: IBenefitLevelTabProps) => (
  <li
    className={classnames(styles.benefitsLevel, {
      [styles.benefitsLevelActive]: isActive,
    })}
    style={{
      color: isActive ? color : 'transparent',
      WebkitTextStrokeWidth: isActive ? '0px' : '1px',
      WebkitTextStrokeColor: color,
    }}
    onClick={onClick}
  >
    {title}
  </li>
);

export default BenefitLevelTab;
