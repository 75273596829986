import React from 'react';
import classnames from 'classnames';
import Heading from 'components/shared/heading/Heading';

import ReferalCode from './referal-code/ReferalCode';
import Social from './social/Social';
import styles from './ShareWithFriends.module.scss';

interface Props {
  className?: string;
  setRef?: React.RefObject<HTMLDivElement>;
}

const ShareWithFriends: React.FC<Props> = ({ className, setRef }) => (
  <section className={classnames(styles.shareWithFriends, className)} ref={setRef}>
    <header className={styles.shareWithFriendsHeader}>
      <Heading
        color="#FF3800"
        shadowColor="#FF0BA9"
        underlineColor="#FF0BA9"
        underlineDirection="start"
        underlineOffset="20px"
        className={styles.shareWithFriendsTitle}
      >
        share with friends
      </Heading>
    </header>

    <div className={styles.shareWithFriendsBody}>
      Want to enjoy CLICK with some friends? Click below to send your friends unique code and
      username. Once they buy their first CLICK and enter the proof of purchase code, we’ll put 1000
      points in your account. So easy, right?
    </div>

    <footer className={styles.shareWithFriendsFooter}>
      <ReferalCode />
      <Social />
    </footer>
  </section>
);

export default ShareWithFriends;
