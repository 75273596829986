import React from 'react';

import useAuth from 'hooks/useAuth';
import { RewardsUserType } from 'services/enums';

const UserIsBudtender: React.FC<any> = ({ children }) => {
  const { profile } = useAuth().state;

  return Boolean(profile?.rewardsUserType === RewardsUserType.budtender) && children;
};

export default UserIsBudtender;
