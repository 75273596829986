import { navigate } from 'gatsby';
import React, { useEffect } from 'react';

import useAuth from 'hooks/useAuth';

interface Props {
  location?: Location;
  path: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component: React.ComponentType<any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

const ValidatedAccount: React.FC<Props> = ({ component: Component, ...rest }: Props) => {
  const {
    state: { isProfileCompleted, isBasicRegistrationCompleted },
  } = useAuth();

  useEffect(() => {
    if (!isBasicRegistrationCompleted) {
      navigate('/account/signup');
    } else if (!isProfileCompleted) {
      navigate('/account/complete');
    }
  }, [isBasicRegistrationCompleted, isProfileCompleted]);

  return <Component {...rest}></Component>;
};

export default ValidatedAccount;
