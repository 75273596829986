import React, { useState, useEffect } from 'react';

import VariantSelect, { optionObject } from '../variant-select/VariantSelect';

import styles from './Cart.module.scss';

interface ICartProps {
  changeQuantity(options: number): void;
  changeSize(options: any): void;
  quantity: number;
  skus: ISwag[];
  selectedSize: ISize;
  sizes: ISize[];
}
interface ISize {
  skuId: string;
  quantity: number;
  value: string;
  label: string;
}
interface ISwag {
  skuId: number;
  name: string;
  imageUrl: string;
  quantity: number;
  productId: number;
  price: number;
  attributes: IAttribute[];
}
interface IAttribute {
  id: string;
  name: string;
  value: string;
  isVisible?: boolean;
  possibleValues: string;
}

const Cart = ({ changeQuantity, quantity, skus, changeSize, selectedSize, sizes }: ICartProps) => {
  let product = skus[0] || {};
  const cart = {
    name: product.name,
    points: product.price,
    url: product.imageUrl,
  };

  const maxQuantity = selectedSize.quantity || product.quantity;

  const onChange = (value: string) => {
    if (+value < maxQuantity) {
      changeQuantity(+value);
    } else {
      if (+value > maxQuantity) {
        changeQuantity(+maxQuantity);
      }
    }
  };

  return (
    <div className={styles.cart}>
      <div className={styles.cartBody}>
        <img src={cart.url} className={styles.cartImage} />
        <div className={styles.cartDetails}>
          <div className={styles.title}>{cart.name}</div>
          <div className={styles.price}>{cart.points} Points</div>
          <div className={styles.productDetails}>
            <VariantSelect
              onChange={(option) => {
                changeSize(option);
              }}
              label="Size"
              options={sizes}
              defaultText="One size"
              value={selectedSize}
            />
            <VariantSelect
              onChange={() => {}}
              label="Color"
              options={[]}
              defaultText="MULTI COLOR"
              value={{}}
            />
            <div className={styles.quantity}>
              <span>QUANTITY</span>
              <input
                value={quantity || ''}
                onChange={(e) => onChange(e.target?.value)}
                max={maxQuantity}
                min="0"
                type="number"
              />
            </div>
          </div>
        </div>
      </div>
      <div className={styles.cartFooter}>
        <div className={styles.title}>Total to pay</div>
        <div className={styles.price}>{cart.points * quantity} Points</div>
      </div>
    </div>
  );
};

export default Cart;
